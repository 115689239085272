export const MainLayout = () => import('../../node_modules/sitis-nuxt/lib/cms/layouts/MainLayout.vue' /* webpackChunkName: "components/main-layout" */).then(c => wrapFunctional(c.default || c))
export const WideLayout = () => import('../../node_modules/sitis-nuxt/lib/cms/layouts/WideLayout.vue' /* webpackChunkName: "components/wide-layout" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../node_modules/sitis-nuxt/lib/cms/layouts/error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const CmsPage = () => import('../../node_modules/sitis-nuxt/lib/cms/CmsPage.vue' /* webpackChunkName: "components/cms-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlaceholder = () => import('../../node_modules/sitis-nuxt/lib/cms/LayoutPlaceholder.vue' /* webpackChunkName: "components/layout-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PageBreadcrumbs = () => import('../../components/page/PageBreadcrumbs.vue' /* webpackChunkName: "components/page-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Helpers = () => import('../../node_modules/sitis-nuxt/lib/cms/helpers/index.ts' /* webpackChunkName: "components/helpers" */).then(c => wrapFunctional(c.default || c))
export const HelpersRenderContent = () => import('../../node_modules/sitis-nuxt/lib/cms/helpers/renderContent.ts' /* webpackChunkName: "components/helpers-render-content" */).then(c => wrapFunctional(c.default || c))
export const AboutBlock = () => import('../../cms/blocks/AboutBlock.vue' /* webpackChunkName: "components/about-block" */).then(c => wrapFunctional(c.default || c))
export const AccountShopBlock = () => import('../../cms/blocks/AccountShopBlock.vue' /* webpackChunkName: "components/account-shop-block" */).then(c => wrapFunctional(c.default || c))
export const AdvantagesBlock = () => import('../../cms/blocks/AdvantagesBlock.vue' /* webpackChunkName: "components/advantages-block" */).then(c => wrapFunctional(c.default || c))
export const ArticleBlock = () => import('../../cms/blocks/ArticleBlock.vue' /* webpackChunkName: "components/article-block" */).then(c => wrapFunctional(c.default || c))
export const CashBackBannerBlock = () => import('../../cms/blocks/CashBackBannerBlock.vue' /* webpackChunkName: "components/cash-back-banner-block" */).then(c => wrapFunctional(c.default || c))
export const CashBackRanksBlock = () => import('../../cms/blocks/CashBackRanksBlock.vue' /* webpackChunkName: "components/cash-back-ranks-block" */).then(c => wrapFunctional(c.default || c))
export const CashBackTipsBlock = () => import('../../cms/blocks/CashBackTipsBlock.vue' /* webpackChunkName: "components/cash-back-tips-block" */).then(c => wrapFunctional(c.default || c))
export const CashBackWhatRankBlock = () => import('../../cms/blocks/CashBackWhatRankBlock.vue' /* webpackChunkName: "components/cash-back-what-rank-block" */).then(c => wrapFunctional(c.default || c))
export const CkEditorBlock = () => import('../../cms/blocks/CkEditorBlock.vue' /* webpackChunkName: "components/ck-editor-block" */).then(c => wrapFunctional(c.default || c))
export const CountriesBlock = () => import('../../cms/blocks/CountriesBlock.vue' /* webpackChunkName: "components/countries-block" */).then(c => wrapFunctional(c.default || c))
export const CountryServiceBlock = () => import('../../cms/blocks/CountryServiceBlock.vue' /* webpackChunkName: "components/country-service-block" */).then(c => wrapFunctional(c.default || c))
export const DocBlock = () => import('../../cms/blocks/DocBlock.vue' /* webpackChunkName: "components/doc-block" */).then(c => wrapFunctional(c.default || c))
export const DocV2Block = () => import('../../cms/blocks/DocV2Block.vue' /* webpackChunkName: "components/doc-v2-block" */).then(c => wrapFunctional(c.default || c))
export const DocV2ContainerBlock = () => import('../../cms/blocks/DocV2ContainerBlock.vue' /* webpackChunkName: "components/doc-v2-container-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationCodeBlock = () => import('../../cms/blocks/DocumentationCodeBlock.vue' /* webpackChunkName: "components/documentation-code-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationEditorBlock = () => import('../../cms/blocks/DocumentationEditorBlock.vue' /* webpackChunkName: "components/documentation-editor-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationLineBlock = () => import('../../cms/blocks/DocumentationLineBlock.vue' /* webpackChunkName: "components/documentation-line-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationParentBlock = () => import('../../cms/blocks/DocumentationParentBlock.vue' /* webpackChunkName: "components/documentation-parent-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationQuestionBlock = () => import('../../cms/blocks/DocumentationQuestionBlock.vue' /* webpackChunkName: "components/documentation-question-block" */).then(c => wrapFunctional(c.default || c))
export const DocumentationTimelineBlock = () => import('../../cms/blocks/DocumentationTimelineBlock.vue' /* webpackChunkName: "components/documentation-timeline-block" */).then(c => wrapFunctional(c.default || c))
export const FaqBlock = () => import('../../cms/blocks/FaqBlock.vue' /* webpackChunkName: "components/faq-block" */).then(c => wrapFunctional(c.default || c))
export const FeaturesTextBlock = () => import('../../cms/blocks/FeaturesTextBlock.vue' /* webpackChunkName: "components/features-text-block" */).then(c => wrapFunctional(c.default || c))
export const FeaturesTextIconsBlock = () => import('../../cms/blocks/FeaturesTextIconsBlock.vue' /* webpackChunkName: "components/features-text-icons-block" */).then(c => wrapFunctional(c.default || c))
export const FormBlock = () => import('../../cms/blocks/FormBlock.vue' /* webpackChunkName: "components/form-block" */).then(c => wrapFunctional(c.default || c))
export const FreeNumbersBlock = () => import('../../cms/blocks/FreeNumbersBlock.vue' /* webpackChunkName: "components/free-numbers-block" */).then(c => wrapFunctional(c.default || c))
export const HeadingBlock = () => import('../../cms/blocks/HeadingBlock.vue' /* webpackChunkName: "components/heading-block" */).then(c => wrapFunctional(c.default || c))
export const HowWorks = () => import('../../cms/blocks/HowWorks.vue' /* webpackChunkName: "components/how-works" */).then(c => wrapFunctional(c.default || c))
export const HtmlBlock = () => import('../../cms/blocks/HtmlBlock.vue' /* webpackChunkName: "components/html-block" */).then(c => wrapFunctional(c.default || c))
export const ImageBlock = () => import('../../cms/blocks/ImageBlock.vue' /* webpackChunkName: "components/image-block" */).then(c => wrapFunctional(c.default || c))
export const ImageGalleryBlock = () => import('../../cms/blocks/ImageGalleryBlock.vue' /* webpackChunkName: "components/image-gallery-block" */).then(c => wrapFunctional(c.default || c))
export const LastArticlesBlock = () => import('../../cms/blocks/LastArticlesBlock.vue' /* webpackChunkName: "components/last-articles-block" */).then(c => wrapFunctional(c.default || c))
export const LastReviewsBlock = () => import('../../cms/blocks/LastReviewsBlock.vue' /* webpackChunkName: "components/last-reviews-block" */).then(c => wrapFunctional(c.default || c))
export const LicensesAdvantagesBlock = () => import('../../cms/blocks/LicensesAdvantagesBlock.vue' /* webpackChunkName: "components/licenses-advantages-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerAllyouneedBlock = () => import('../../cms/blocks/PartnerAllyouneedBlock.vue' /* webpackChunkName: "components/partner-allyouneed-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerCalculatorBlock = () => import('../../cms/blocks/PartnerCalculatorBlock.vue' /* webpackChunkName: "components/partner-calculator-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerFaqBlock = () => import('../../cms/blocks/PartnerFaqBlock.vue' /* webpackChunkName: "components/partner-faq-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerFormBlock = () => import('../../cms/blocks/PartnerFormBlock.vue' /* webpackChunkName: "components/partner-form-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerHeadingBlock = () => import('../../cms/blocks/PartnerHeadingBlock.vue' /* webpackChunkName: "components/partner-heading-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerStatistcsBlock = () => import('../../cms/blocks/PartnerStatistcsBlock.vue' /* webpackChunkName: "components/partner-statistcs-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerTextwithImagesBlock = () => import('../../cms/blocks/PartnerTextwithImagesBlock.vue' /* webpackChunkName: "components/partner-textwith-images-block" */).then(c => wrapFunctional(c.default || c))
export const PartnerTextwithheadingBlock = () => import('../../cms/blocks/PartnerTextwithheadingBlock.vue' /* webpackChunkName: "components/partner-textwithheading-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentsBlock = () => import('../../cms/blocks/PaymentsBlock.vue' /* webpackChunkName: "components/payments-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStaticBlock = () => import('../../cms/blocks/PaymentsStaticBlock.vue' /* webpackChunkName: "components/payments-static-block" */).then(c => wrapFunctional(c.default || c))
export const PricesBlock = () => import('../../cms/blocks/PricesBlock.vue' /* webpackChunkName: "components/prices-block" */).then(c => wrapFunctional(c.default || c))
export const ProductLicensesBlock = () => import('../../cms/blocks/ProductLicensesBlock.vue' /* webpackChunkName: "components/product-licenses-block" */).then(c => wrapFunctional(c.default || c))
export const ReferalBlock = () => import('../../cms/blocks/ReferalBlock.vue' /* webpackChunkName: "components/referal-block" */).then(c => wrapFunctional(c.default || c))
export const ReferalConditionsBlock = () => import('../../cms/blocks/ReferalConditionsBlock.vue' /* webpackChunkName: "components/referal-conditions-block" */).then(c => wrapFunctional(c.default || c))
export const ReferalHeadingBlock = () => import('../../cms/blocks/ReferalHeadingBlock.vue' /* webpackChunkName: "components/referal-heading-block" */).then(c => wrapFunctional(c.default || c))
export const ReferalTextBlock = () => import('../../cms/blocks/ReferalTextBlock.vue' /* webpackChunkName: "components/referal-text-block" */).then(c => wrapFunctional(c.default || c))
export const ServiceInverseBlock = () => import('../../cms/blocks/ServiceInverseBlock.vue' /* webpackChunkName: "components/service-inverse-block" */).then(c => wrapFunctional(c.default || c))
export const ServicesBlock = () => import('../../cms/blocks/ServicesBlock.vue' /* webpackChunkName: "components/services-block" */).then(c => wrapFunctional(c.default || c))
export const SoftFaqBlock = () => import('../../cms/blocks/SoftFaqBlock.vue' /* webpackChunkName: "components/soft-faq-block" */).then(c => wrapFunctional(c.default || c))
export const SoftFormBlock = () => import('../../cms/blocks/SoftFormBlock.vue' /* webpackChunkName: "components/soft-form-block" */).then(c => wrapFunctional(c.default || c))
export const SoftHeadingBlock = () => import('../../cms/blocks/SoftHeadingBlock.vue' /* webpackChunkName: "components/soft-heading-block" */).then(c => wrapFunctional(c.default || c))
export const SoftServicesBlock = () => import('../../cms/blocks/SoftServicesBlock.vue' /* webpackChunkName: "components/soft-services-block" */).then(c => wrapFunctional(c.default || c))
export const SpecialBlock = () => import('../../cms/blocks/SpecialBlock.vue' /* webpackChunkName: "components/special-block" */).then(c => wrapFunctional(c.default || c))
export const TextBannerBlock = () => import('../../cms/blocks/TextBannerBlock.vue' /* webpackChunkName: "components/text-banner-block" */).then(c => wrapFunctional(c.default || c))
export const TextBannerFormBlock = () => import('../../cms/blocks/TextBannerFormBlock.vue' /* webpackChunkName: "components/text-banner-form-block" */).then(c => wrapFunctional(c.default || c))
export const TwoColumnsBlock = () => import('../../cms/blocks/TwoColumnsBlock.vue' /* webpackChunkName: "components/two-columns-block" */).then(c => wrapFunctional(c.default || c))
export const VideoBlock = () => import('../../cms/blocks/VideoBlock.vue' /* webpackChunkName: "components/video-block" */).then(c => wrapFunctional(c.default || c))
export const CountryServiceElementLink = () => import('../../cms/blocks/country-service/ElementLink.vue' /* webpackChunkName: "components/country-service-element-link" */).then(c => wrapFunctional(c.default || c))
export const CountryServiceCard = () => import('../../cms/blocks/country-service/ServiceCard.vue' /* webpackChunkName: "components/country-service-card" */).then(c => wrapFunctional(c.default || c))
export const FaqHome = () => import('../../cms/blocks/faq/FaqHome.vue' /* webpackChunkName: "components/faq-home" */).then(c => wrapFunctional(c.default || c))
export const FaqMain = () => import('../../cms/blocks/faq/FaqMain.vue' /* webpackChunkName: "components/faq-main" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchCountry = () => import('../../cms/blocks/general-search/GeneralSearchCountry.vue' /* webpackChunkName: "components/general-search-country" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchCountryTable = () => import('../../cms/blocks/general-search/GeneralSearchCountryTable.vue' /* webpackChunkName: "components/general-search-country-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchCountryTableRow = () => import('../../cms/blocks/general-search/GeneralSearchCountryTableRow.vue' /* webpackChunkName: "components/general-search-country-table-row" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchService = () => import('../../cms/blocks/general-search/GeneralSearchService.vue' /* webpackChunkName: "components/general-search-service" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchServiceTable = () => import('../../cms/blocks/general-search/GeneralSearchServiceTable.vue' /* webpackChunkName: "components/general-search-service-table" */).then(c => wrapFunctional(c.default || c))
export const FreeCountries = () => import('../../cms/blocks/free-numbers/FreeCountries.vue' /* webpackChunkName: "components/free-countries" */).then(c => wrapFunctional(c.default || c))
export const FreeCountriesNumbers = () => import('../../cms/blocks/free-numbers/FreeCountriesNumbers.vue' /* webpackChunkName: "components/free-countries-numbers" */).then(c => wrapFunctional(c.default || c))
export const FreeNumbersList = () => import('../../cms/blocks/free-numbers/FreeNumbersList.vue' /* webpackChunkName: "components/free-numbers-list" */).then(c => wrapFunctional(c.default || c))
export const FreeNumbersPopUpRegistration = () => import('../../cms/blocks/free-numbers/PopUpRegistration.vue' /* webpackChunkName: "components/free-numbers-pop-up-registration" */).then(c => wrapFunctional(c.default || c))
export const PaymentsButtonsTemplate = () => import('../../cms/blocks/payments/ButtonsTemplate.vue' /* webpackChunkName: "components/payments-buttons-template" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPayAgreeCheckbox = () => import('../../cms/blocks/payments/PayAgreeCheckbox.vue' /* webpackChunkName: "components/payments-pay-agree-checkbox" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPayCard = () => import('../../cms/blocks/payments/PayCard.vue' /* webpackChunkName: "components/payments-pay-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPayModal = () => import('../../cms/blocks/payments/PayModal.vue' /* webpackChunkName: "components/payments-pay-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPayModalInfo = () => import('../../cms/blocks/payments/PayModalInfo.vue' /* webpackChunkName: "components/payments-pay-modal-info" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPayServiceBtn = () => import('../../cms/blocks/payments/PayServiceBtn.vue' /* webpackChunkName: "components/payments-pay-service-btn" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentConvertor = () => import('../../cms/blocks/payments/PaymentConvertor.vue' /* webpackChunkName: "components/payments-payment-convertor" */).then(c => wrapFunctional(c.default || c))
export const LastArticlesBlockArticleCard = () => import('../../cms/blocks/last-articles-block/ArticleCard.vue' /* webpackChunkName: "components/last-articles-block-article-card" */).then(c => wrapFunctional(c.default || c))
export const ReviewsReviewCard = () => import('../../cms/blocks/reviews/ReviewCard.vue' /* webpackChunkName: "components/reviews-review-card" */).then(c => wrapFunctional(c.default || c))
export const ReviewsReviewForm = () => import('../../cms/blocks/reviews/ReviewForm.vue' /* webpackChunkName: "components/reviews-review-form" */).then(c => wrapFunctional(c.default || c))
export const Reviews = () => import('../../cms/blocks/reviews/Reviews.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c))
export const ReviewsCreateModal = () => import('../../cms/blocks/reviews/ReviewsCreateModal.vue' /* webpackChunkName: "components/reviews-create-modal" */).then(c => wrapFunctional(c.default || c))
export const ReviewsList = () => import('../../cms/blocks/reviews/ReviewsList.vue' /* webpackChunkName: "components/reviews-list" */).then(c => wrapFunctional(c.default || c))
export const TextBannerFormModalFeedBack = () => import('../../cms/blocks/text-banner-form/ModalFeedBack.vue' /* webpackChunkName: "components/text-banner-form-modal-feed-back" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchSortIconNumbersASK = () => import('../../cms/blocks/general-search/sort/IconNumbersASK.vue' /* webpackChunkName: "components/general-search-sort-icon-numbers-a-s-k" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchSortIconNumbersDESK = () => import('../../cms/blocks/general-search/sort/IconNumbersDESK.vue' /* webpackChunkName: "components/general-search-sort-icon-numbers-d-e-s-k" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchSortIconSortTriangle = () => import('../../cms/blocks/general-search/sort/IconSortTriangle.vue' /* webpackChunkName: "components/general-search-sort-icon-sort-triangle" */).then(c => wrapFunctional(c.default || c))
export const GeneralSearchSortIconSortTriangleReverse = () => import('../../cms/blocks/general-search/sort/IconSortTriangleReverse.vue' /* webpackChunkName: "components/general-search-sort-icon-sort-triangle-reverse" */).then(c => wrapFunctional(c.default || c))
export const PaymentsExtraPaymentServicesAliPayCard = () => import('../../cms/blocks/payments/extra-payment-services/AliPayCard.vue' /* webpackChunkName: "components/payments-extra-payment-services-ali-pay-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentsExtraPaymentServicesPayRegistrationUser = () => import('../../cms/blocks/payments/extra-payment-services/PayRegistrationUser.vue' /* webpackChunkName: "components/payments-extra-payment-services-pay-registration-user" */).then(c => wrapFunctional(c.default || c))
export const AccountShopDialogOffer = () => import('../../components/account-shop/DialogOffer.vue' /* webpackChunkName: "components/account-shop-dialog-offer" */).then(c => wrapFunctional(c.default || c))
export const AccountShopMainSectionInfo = () => import('../../components/account-shop/MainSectionInfo.vue' /* webpackChunkName: "components/account-shop-main-section-info" */).then(c => wrapFunctional(c.default || c))
export const AccountShopMyServices = () => import('../../components/account-shop/MyServices.vue' /* webpackChunkName: "components/account-shop-my-services" */).then(c => wrapFunctional(c.default || c))
export const AccountShopSelectedOffer = () => import('../../components/account-shop/SelectedOffer.vue' /* webpackChunkName: "components/account-shop-selected-offer" */).then(c => wrapFunctional(c.default || c))
export const AccountShopSelectedService = () => import('../../components/account-shop/SelectedService.vue' /* webpackChunkName: "components/account-shop-selected-service" */).then(c => wrapFunctional(c.default || c))
export const BlogArticleCard = () => import('../../components/blog/ArticleCard.vue' /* webpackChunkName: "components/blog-article-card" */).then(c => wrapFunctional(c.default || c))
export const BlogArticleReadMoreCard = () => import('../../components/blog/ArticleReadMoreCard.vue' /* webpackChunkName: "components/blog-article-read-more-card" */).then(c => wrapFunctional(c.default || c))
export const BlogComments = () => import('../../components/blog/Comments.vue' /* webpackChunkName: "components/blog-comments" */).then(c => wrapFunctional(c.default || c))
export const BlogMainImageBlob = () => import('../../components/blog/MainImageBlob.vue' /* webpackChunkName: "components/blog-main-image-blob" */).then(c => wrapFunctional(c.default || c))
export const BlogTagsServices = () => import('../../components/blog/TagsServices.vue' /* webpackChunkName: "components/blog-tags-services" */).then(c => wrapFunctional(c.default || c))
export const ApiDocs = () => import('../../components/api-docs/ApiDocs.vue' /* webpackChunkName: "components/api-docs" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsInfoCard = () => import('../../components/api-docs/InfoCard.vue' /* webpackChunkName: "components/api-docs-info-card" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsInfoPopup = () => import('../../components/api-docs/InfoPopup.vue' /* webpackChunkName: "components/api-docs-info-popup" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsNavBlock = () => import('../../components/api-docs/NavBlock.vue' /* webpackChunkName: "components/api-docs-nav-block" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsNavigation = () => import('../../components/api-docs/Navigation.vue' /* webpackChunkName: "components/api-docs-navigation" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsTestingApiMethod = () => import('../../components/api-docs/TestingApiMethod.vue' /* webpackChunkName: "components/api-docs-testing-api-method" */).then(c => wrapFunctional(c.default || c))
export const CommonAdditionalCashbackAccrual = () => import('../../components/common/AdditionalCashbackAccrual.vue' /* webpackChunkName: "components/common-additional-cashback-accrual" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomMultiselect = () => import('../../components/common/CustomMultiselect.vue' /* webpackChunkName: "components/common-custom-multiselect" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomToasted = () => import('../../components/common/CustomToasted.vue' /* webpackChunkName: "components/common-custom-toasted" */).then(c => wrapFunctional(c.default || c))
export const CommonDatePickerNative = () => import('../../components/common/DatePickerNative.vue' /* webpackChunkName: "components/common-date-picker-native" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/Header.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonHelpDisabledRuCountry = () => import('../../components/common/HelpDisabledRuCountry.vue' /* webpackChunkName: "components/common-help-disabled-ru-country" */).then(c => wrapFunctional(c.default || c))
export const CommonLoadingModal = () => import('../../components/common/LoadingModal.vue' /* webpackChunkName: "components/common-loading-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonMobileMenu = () => import('../../components/common/MobileMenu.vue' /* webpackChunkName: "components/common-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonPagination = () => import('../../components/common/Pagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonSearch = () => import('../../components/common/Search.vue' /* webpackChunkName: "components/common-search" */).then(c => wrapFunctional(c.default || c))
export const CopyDockBlockDocBlockOrigin = () => import('../../components/copyDockBlock/DocBlockOrigin.vue' /* webpackChunkName: "components/copy-dock-block-doc-block-origin" */).then(c => wrapFunctional(c.default || c))
export const CountriesAvailableNumber = () => import('../../components/countries/AvailableNumber.vue' /* webpackChunkName: "components/countries-available-number" */).then(c => wrapFunctional(c.default || c))
export const CountriesLoaderNumbersCard = () => import('../../components/countries/LoaderNumbersCard.vue' /* webpackChunkName: "components/countries-loader-numbers-card" */).then(c => wrapFunctional(c.default || c))
export const CountriesPopularServiceCard = () => import('../../components/countries/PopularServiceCard.vue' /* webpackChunkName: "components/countries-popular-service-card" */).then(c => wrapFunctional(c.default || c))
export const Feedback = () => import('../../components/feedback/Feedback.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c))
export const FeedbackHomePage = () => import('../../components/feedback/FeedbackHomePage.vue' /* webpackChunkName: "components/feedback-home-page" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileCurrencyToggle = () => import('../../components/header-mobile/CurrencyToggle.vue' /* webpackChunkName: "components/header-mobile-currency-toggle" */).then(c => wrapFunctional(c.default || c))
export const HomepageFaq = () => import('../../components/homepage/Faq.vue' /* webpackChunkName: "components/homepage-faq" */).then(c => wrapFunctional(c.default || c))
export const LayoutsButtonEditCmsContent = () => import('../../components/layouts/ButtonEditCmsContent.vue' /* webpackChunkName: "components/layouts-button-edit-cms-content" */).then(c => wrapFunctional(c.default || c))
export const LayoutsErrorContent = () => import('../../components/layouts/ErrorContent.vue' /* webpackChunkName: "components/layouts-error-content" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderMobile = () => import('../../components/layouts/HeaderMobile.vue' /* webpackChunkName: "components/layouts-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyProgramCashback = () => import('../../components/loyalty-program/Cashback.vue' /* webpackChunkName: "components/loyalty-program-cashback" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyProgramHistory = () => import('../../components/loyalty-program/History.vue' /* webpackChunkName: "components/loyalty-program-history" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyProgramConditions = () => import('../../components/loyalty-program/ProgramConditions.vue' /* webpackChunkName: "components/loyalty-program-conditions" */).then(c => wrapFunctional(c.default || c))
export const ModalsBaseModal = () => import('../../components/modals/BaseModal.vue' /* webpackChunkName: "components/modals-base-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCloseModalButton = () => import('../../components/modals/CloseModalButton.vue' /* webpackChunkName: "components/modals-close-modal-button" */).then(c => wrapFunctional(c.default || c))
export const PageBannerForBuyAccounts = () => import('../../components/page/BannerForBuyAccounts.vue' /* webpackChunkName: "components/page-banner-for-buy-accounts" */).then(c => wrapFunctional(c.default || c))
export const PageLinkToAccount = () => import('../../components/page/LinkToAccount.vue' /* webpackChunkName: "components/page-link-to-account" */).then(c => wrapFunctional(c.default || c))
export const PageSPage = () => import('../../components/page/SPage.vue' /* webpackChunkName: "components/page-s-page" */).then(c => wrapFunctional(c.default || c))
export const PageServiceCountriesSelect = () => import('../../components/page-service/CountriesSelect.vue' /* webpackChunkName: "components/page-service-countries-select" */).then(c => wrapFunctional(c.default || c))
export const PageServiceCountryCard = () => import('../../components/page-service/CountryCard.vue' /* webpackChunkName: "components/page-service-country-card" */).then(c => wrapFunctional(c.default || c))
export const PageServiceCurrentCountry = () => import('../../components/page-service/CurrentCountry.vue' /* webpackChunkName: "components/page-service-current-country" */).then(c => wrapFunctional(c.default || c))
export const PageServiceMainSection = () => import('../../components/page-service/MainSection.vue' /* webpackChunkName: "components/page-service-main-section" */).then(c => wrapFunctional(c.default || c))
export const PartnerStatistcsChar = () => import('../../components/partner-statistcs/Char.vue' /* webpackChunkName: "components/partner-statistcs-char" */).then(c => wrapFunctional(c.default || c))
export const SelectServiceCard = () => import('../../components/select-service-register/ServiceCard.vue' /* webpackChunkName: "components/select-service-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesList = () => import('../../components/profile/LanguagesList.vue' /* webpackChunkName: "components/profile-languages-list" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyPersonalInformation = () => import('../../components/profile/MyPersonalInformation.vue' /* webpackChunkName: "components/profile-my-personal-information" */).then(c => wrapFunctional(c.default || c))
export const ProfileNavigationPanel = () => import('../../components/profile/NavigationPanel.vue' /* webpackChunkName: "components/profile-navigation-panel" */).then(c => wrapFunctional(c.default || c))
export const ProfileNavigationPanelMobile = () => import('../../components/profile/NavigationPanelMobile.vue' /* webpackChunkName: "components/profile-navigation-panel-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProfileRequestNewServiceForm = () => import('../../components/profile/RequestNewServiceForm.vue' /* webpackChunkName: "components/profile-request-new-service-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileServiceListItem = () => import('../../components/profile/ServiceListItem.vue' /* webpackChunkName: "components/profile-service-list-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileServicesListLoader = () => import('../../components/profile/ServicesListLoader.vue' /* webpackChunkName: "components/profile-services-list-loader" */).then(c => wrapFunctional(c.default || c))
export const ServiceArticleCard = () => import('../../components/service/ArticleCard.vue' /* webpackChunkName: "components/service-article-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceCustomAutocompleteServices = () => import('../../components/service/CustomAutocompleteServices.vue' /* webpackChunkName: "components/service-custom-autocomplete-services" */).then(c => wrapFunctional(c.default || c))
export const ServiceTagsArticles = () => import('../../components/service/TagsArticles.vue' /* webpackChunkName: "components/service-tags-articles" */).then(c => wrapFunctional(c.default || c))
export const SpecialBlockSlideItem = () => import('../../components/special-block/SlideItem.vue' /* webpackChunkName: "components/special-block-slide-item" */).then(c => wrapFunctional(c.default || c))
export const TestClsList = () => import('../../components/test-cls-list/cls-list.js' /* webpackChunkName: "components/test-cls-list" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsTestingApiMethodComponentApi = () => import('../../components/api-docs/testing-api-method/ComponentApi.vue' /* webpackChunkName: "components/api-docs-testing-api-method-component-api" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsTestingApiMethodComponentSelect = () => import('../../components/api-docs/testing-api-method/ComponentSelect.vue' /* webpackChunkName: "components/api-docs-testing-api-method-component-select" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsTestingApiMethodComponentString = () => import('../../components/api-docs/testing-api-method/ComponentString.vue' /* webpackChunkName: "components/api-docs-testing-api-method-component-string" */).then(c => wrapFunctional(c.default || c))
export const ApiDocsTestingApiMethodCustomAutocomplete = () => import('../../components/api-docs/testing-api-method/CustomAutocomplete.vue' /* webpackChunkName: "components/api-docs-testing-api-method-custom-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomToastedToast = () => import('../../components/common/custom-toasted/Toast.vue' /* webpackChunkName: "components/common-custom-toasted-toast" */).then(c => wrapFunctional(c.default || c))
export const CommonMobileMenuElementMobileMenu = () => import('../../components/common/mobile-menu/ElementMobileMenu.vue' /* webpackChunkName: "components/common-mobile-menu-element-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchArticles = () => import('../../components/common/search/Articles.vue' /* webpackChunkName: "components/common-search-articles" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchCategories = () => import('../../components/common/search/Categories.vue' /* webpackChunkName: "components/common-search-categories" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchProducts = () => import('../../components/common/search/Products.vue' /* webpackChunkName: "components/common-search-products" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchService = () => import('../../components/common/search/Service.vue' /* webpackChunkName: "components/common-search-service" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchSpecialists = () => import('../../components/common/search/Specialists.vue' /* webpackChunkName: "components/common-search-specialists" */).then(c => wrapFunctional(c.default || c))
export const CommonHeaderCurrencyToggle = () => import('../../components/common/header/CurrencyToggle.vue' /* webpackChunkName: "components/common-header-currency-toggle" */).then(c => wrapFunctional(c.default || c))
export const CommonHeaderLangToggle = () => import('../../components/common/header/LangToggle.vue' /* webpackChunkName: "components/common-header-lang-toggle" */).then(c => wrapFunctional(c.default || c))
export const CommonHeaderPopupRedirectFromRu = () => import('../../components/common/header/PopupRedirectFromRu.vue' /* webpackChunkName: "components/common-header-popup-redirect-from-ru" */).then(c => wrapFunctional(c.default || c))
export const PartnerActivationsHistoryFilter = () => import('../../components/partner/activations-history/Filter.vue' /* webpackChunkName: "components/partner-activations-history-filter" */).then(c => wrapFunctional(c.default || c))
export const PartnerPriceRegulatorDialogCompetitorsPrices = () => import('../../components/partner/price-regulator/DialogCompetitorsPrices.vue' /* webpackChunkName: "components/partner-price-regulator-dialog-competitors-prices" */).then(c => wrapFunctional(c.default || c))
export const PartnerPriceRegulatorFilter = () => import('../../components/partner/price-regulator/Filter.vue' /* webpackChunkName: "components/partner-price-regulator-filter" */).then(c => wrapFunctional(c.default || c))
export const PartnerPriceRegulatorRowPrice = () => import('../../components/partner/price-regulator/RowPrice.vue' /* webpackChunkName: "components/partner-price-regulator-row-price" */).then(c => wrapFunctional(c.default || c))
export const PartnerFiltersFilterDatePicker = () => import('../../components/partner/filters/FilterDatePicker.vue' /* webpackChunkName: "components/partner-filters-filter-date-picker" */).then(c => wrapFunctional(c.default || c))
export const PartnerFiltersFilterInput = () => import('../../components/partner/filters/FilterInput.vue' /* webpackChunkName: "components/partner-filters-filter-input" */).then(c => wrapFunctional(c.default || c))
export const PartnerFiltersFilterSelect = () => import('../../components/partner/filters/FilterSelect.vue' /* webpackChunkName: "components/partner-filters-filter-select" */).then(c => wrapFunctional(c.default || c))
export const PartnerFiltersFilterTags = () => import('../../components/partner/filters/FilterTags.vue' /* webpackChunkName: "components/partner-filters-filter-tags" */).then(c => wrapFunctional(c.default || c))
export const PartnerTableHeadRowSort = () => import('../../components/partner/table/HeadRowSort.vue' /* webpackChunkName: "components/partner-table-head-row-sort" */).then(c => wrapFunctional(c.default || c))
export const PartnerSimCardPopUpDetailNumber = () => import('../../components/partner/sim-card/PopUpDetailNumber.vue' /* webpackChunkName: "components/partner-sim-card-pop-up-detail-number" */).then(c => wrapFunctional(c.default || c))
export const PartnerWithdrawalFundsBalanceList = () => import('../../components/partner/withdrawal-funds/BalanceList.vue' /* webpackChunkName: "components/partner-withdrawal-funds-balance-list" */).then(c => wrapFunctional(c.default || c))
export const PartnerWithdrawalFundsCurrencyFilter = () => import('../../components/partner/withdrawal-funds/CurrencyFilter.vue' /* webpackChunkName: "components/partner-withdrawal-funds-currency-filter" */).then(c => wrapFunctional(c.default || c))
export const PartnerWithdrawalFundsModalWithdrawal = () => import('../../components/partner/withdrawal-funds/ModalWithdrawal.vue' /* webpackChunkName: "components/partner-withdrawal-funds-modal-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const PartnerWithdrawalFundsTableLoading = () => import('../../components/partner/withdrawal-funds/TableLoading.vue' /* webpackChunkName: "components/partner-withdrawal-funds-table-loading" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListLanguageCard = () => import('../../components/profile/LanguagesList/LanguageCard.vue' /* webpackChunkName: "components/profile-languages-list-language-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListOfferServiceModal = () => import('../../components/profile/LanguagesList/OfferServiceModal.vue' /* webpackChunkName: "components/profile-languages-list-offer-service-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListPopUpHelp = () => import('../../components/profile/LanguagesList/PopUpHelp.vue' /* webpackChunkName: "components/profile-languages-list-pop-up-help" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListPopupRepeatBuyNumber = () => import('../../components/profile/LanguagesList/PopupRepeatBuyNumber.vue' /* webpackChunkName: "components/profile-languages-list-popup-repeat-buy-number" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListRentInformation = () => import('../../components/profile/LanguagesList/RentInformation.vue' /* webpackChunkName: "components/profile-languages-list-rent-information" */).then(c => wrapFunctional(c.default || c))
export const ProfileActiveNumbers = () => import('../../components/profile/active-numbers/ActiveNumbers.vue' /* webpackChunkName: "components/profile-active-numbers" */).then(c => wrapFunctional(c.default || c))
export const ProfileActiveNumbersMultipleSmsRow = () => import('../../components/profile/active-numbers/MultipleSmsRow.vue' /* webpackChunkName: "components/profile-active-numbers-multiple-sms-row" */).then(c => wrapFunctional(c.default || c))
export const ProfileHistoryBalanceItem = () => import('../../components/profile/history-balance/HistoryBalanceItem.vue' /* webpackChunkName: "components/profile-history-balance-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyPurchaseNavPanel = () => import('../../components/profile/my-purchase/MyPurchaseNavPanel.vue' /* webpackChunkName: "components/profile-my-purchase-nav-panel" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyRentNumbers = () => import('../../components/profile/my-rent-numbers/MyRentNumbers.vue' /* webpackChunkName: "components/profile-my-rent-numbers" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyRentNumbersPopUpExtendLease = () => import('../../components/profile/my-rent-numbers/PopUpExtendLease.vue' /* webpackChunkName: "components/profile-my-rent-numbers-pop-up-extend-lease" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyRentNumberCard = () => import('../../components/profile/my-rent-numbers/RentNumberCard.vue' /* webpackChunkName: "components/profile-my-rent-number-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileReadeAccounts = () => import('../../components/profile/ready-accounts/ProfileReadeAccounts.vue' /* webpackChunkName: "components/profile-reade-accounts" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayAgreeCheckbox = () => import('../../components/profile/pay/AgreeCheckbox.vue' /* webpackChunkName: "components/profile-pay-agree-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayAliPay = () => import('../../components/profile/pay/AliPay.vue' /* webpackChunkName: "components/profile-pay-ali-pay" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayButtonsTemplate = () => import('../../components/profile/pay/ButtonsTemplate.vue' /* webpackChunkName: "components/profile-pay-buttons-template" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayExtraCard = () => import('../../components/profile/pay/ExtraCard.vue' /* webpackChunkName: "components/profile-pay-extra-card" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayCountry = () => import('../../components/profile/pay/PayCountry.vue' /* webpackChunkName: "components/profile-pay-country" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayService = () => import('../../components/profile/pay/PayService.vue' /* webpackChunkName: "components/profile-pay-service" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayPaymentConvertor = () => import('../../components/profile/pay/PaymentConvertor.vue' /* webpackChunkName: "components/profile-pay-payment-convertor" */).then(c => wrapFunctional(c.default || c))
export const ProfileSettingsReferralProgram = () => import('../../components/profile/settings/ReferralProgram.vue' /* webpackChunkName: "components/profile-settings-referral-program" */).then(c => wrapFunctional(c.default || c))
export const ProfileSortComponent = () => import('../../components/profile/sort-component/SortComponent.vue' /* webpackChunkName: "components/profile-sort-component" */).then(c => wrapFunctional(c.default || c))
export const ProfileStatisticsNumbersItem = () => import('../../components/profile/statistics-numbers/StatisticsNumbersItem.vue' /* webpackChunkName: "components/profile-statistics-numbers-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListReadyNumberConfirmModal = () => import('../../components/profile/LanguagesList/ready-numbers/ReadyNumberConfirmModal.vue' /* webpackChunkName: "components/profile-languages-list-ready-number-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileLanguagesListReadyNumbers = () => import('../../components/profile/LanguagesList/ready-numbers/ReadyNumbers.vue' /* webpackChunkName: "components/profile-languages-list-ready-numbers" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
